import React from "react";
import { graphql, PageProps } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import pagesMeta from "data/pagesMeta";
import { Faq, Hero } from "components/help";
import SEO from "components/SEO";

const Help: React.FC<PageProps> = ({ data }) => {
  const {
    site: { siteMetadata },
    allJavascriptFrontmatter: {
      edges: [
        {
          node: { frontmatter: pageData },
        },
      ],
    },
  } = data as any;

  const metaImage = getImage(
    pageData.hero.background.childImageSharp.gatsbyImageData
  )?.images?.fallback?.src;

  return (
    <>
      <SEO {...pagesMeta.help} image={`${siteMetadata.siteUrl}${metaImage}`} />
      <Hero backgroundImage={pageData.hero.background} />
      <Faq data={pageData.faqData} />
    </>
  );
};

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        description
        siteUrl
        image
      }
    }
    allJavascriptFrontmatter(
      filter: { frontmatter: { name: { eq: "help" } } }
    ) {
      edges {
        node {
          frontmatter {
            hero {
              background {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            faqData {
              title
              visible
              content {
                question
                answer
              }
            }
          }
        }
      }
    }
  }
`;

Hero.defaultProps = {
  backgroundImage: "",
  mobileBackgroundImage: "",
  useOptimizedBackground: true,
};

export default Help;
